
export const IGNORE_ERRORS = [
  // Redundant errors (avoid extending)
  'Hydration completed but contains mismatches', // TODO: This should not be ignored. Needs to be fixed
  'replaceState', // This should not be ignored
  'TypeMismatchError',
  'ResizeObserver',
  'webkitExitFullScreen',
  'ReportingObserver',
  'HierarchyRequestError',
  'The operation is insecure',
  'in strict mode',
  'Blocked a frame with origin',
  'Ignored attempt to cancel',
  'instantSearchSDKJSBridgeClearHighlight',
  'ibFindAllVideos',
  'loadDownloads',
  'processRandomSelector',
  'Illegal invocation',
  'global code',
  'Decoding failed',
  'Failed to fetch',
  'PaymentAutofillConfig',
  'Navigation cancelled',
  'messagehandlers',
  'Non-Error promise rejection captured',
  'Load failed',
  'webkit.messageHandlers',
  'Aborted',
  'AbortError',
  'captured as promise rejection',
  'IFrameMessageHandler',
  'The operation was aborted',
  'No error message',
  'Request signal is aborted',
  'The user aborted a request',
  'The operation was aborted due to timeout',
  'Network Error',
  'Java object is gone',
  "Failed to read the 'localStorage' property from 'Window': Access is denied for this document",
  'HTTP Client Error with status code',
  'FetchError',
  '[GET]',
  '[POST]',
  'Persisted state failed',
  'NetworkError when attempting to fetch resource',
  'A network error occured',

  // Errors that we can't do much about and rarely happens
  'Unreachable hosts - your application id may be incorrect.',
  'Failed to initialize WebGL',
  'function Error() { [native code] }',
  '<unknown>',
  'illegal access',
  'Java exception was raised during method invocation',
  'Invalid call to runtime.sendMessage()',
  "Cannot read properties of null (reading 'emitsOptions')",
  "Cannot destructure property 'bum' of 'A' as it is null.",
  'Java bridge method invocation error',
  "Cannot read properties of undefined (reading 'cssMode')",
  "Can't find variable: gmo",

  // Errors that intentionally generate 404
  'Post not found:',
  'Page not found:',
  'Not found:',

  // Errors that occur when getting chunks from new code when still having old code in browser (common after deploying)
  "Cannot read properties of undefined (reading 'default')",
  "undefined is not an object (evaluating 'r.default')",
  "undefined is not an object (evaluating 't.default')",
  "undefined is not an object (evaluating 'e.default')",
  "undefined is not an object (evaluating 'c.default')",
  "undefined is not an object (evaluating 'o.default')",
  'Couldn\'t resolve component "default"',
  '[GET] "/_nuxt/builds/meta/',
  'Unable to preload CSS for',
  '[nuxt] Error creating app manifest matcher. undefined',
  'error loading dynamically imported module:',
  'Importing a module script failed.',
]
