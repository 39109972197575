import dayjs from 'dayjs'
import type { Storage } from '@packages/cache/storages'

const EXPIRATION_STORAGE_KEY_SUFFIX = 'exp'

const isExpired = async (expirationStorageKey: string, storage: Storage) => {
  const expirationValue = await storage.get(expirationStorageKey)
  return typeof expirationValue !== 'string' || parseInt(expirationValue) < dayjs().unix()
}

export const getCached = async (
  key: string,
  storage: Storage,
  clearOnExpire?: boolean
): Promise<any | null> => {
  const expirationStorageKey = `${key}_${EXPIRATION_STORAGE_KEY_SUFFIX}`

  if (!(await isExpired(expirationStorageKey, storage))) {
    return await storage.get(key)
  }

  if (clearOnExpire) {
    await storage.remove(key)
  }

  await storage.remove(expirationStorageKey)

  return null
}

export const setCached = async (
  key: string,
  data: any,
  expiration: number,
  storage: Storage
) => {
  const expirationStorageKey = `${key}_${EXPIRATION_STORAGE_KEY_SUFFIX}`

  if (await storage.get(expirationStorageKey) === null) {
    const expirationSeconds = expiration * .001
    await storage.set(expirationStorageKey, (dayjs().unix() + expirationSeconds).toString())
  }

  if (await isExpired(expirationStorageKey, storage)) {
    await storage.remove(key)
  } else {
    await storage.set(key, data)
  }
}
