const secondMS = 1000
const minuteMS = secondMS * 60;
const hourMS = minuteMS * 60;

export const fourMinutes = minuteMS * 4
export const fiveSeconds = secondMS * 5
export const shortDuration = minuteMS * 2
export const mediumDuration = minuteMS * 20
export const hour = hourMS
export const halfHour = hourMS / 2
export const longDuration = hourMS * 3
export const day = hourMS * 12

export default {
  fiveSeconds,
  shortDuration,
  mediumDuration,
  halfHour,
  hour,
  longDuration,
  day,
  fourMinutes,
};
