import dayjs from 'dayjs'

import localStorageKeys from '@layers/web/constants/local-storage-keys.ts'
import { useLocaleStore } from '@layers/web/stores/locale'
import { getGravatarByEmail } from '@layers/web/utils/auth/auth'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    token: null,
    loading: false,
    bookings: [],
    favourites: [],
    authFeedback: null,
    feedback: null,
    isInitiated: false,
    memberPagePath: '',
    memberPageTab: 'logga-in',
    memberPageTabs: [],
    memberActiveSingle: false,
    memberProfile: null,
    memberSingleList: [],
  }),
  persist: {
    paths: [
      'favourites',
      'bookings',
      'user',
      'token',
      'memberActiveSingle',
    ],
    storage: persistedState.localStorage,
  },
  getters: {
    getToken: (state) => {
      if (process.browser) {
        const token = window.localStorage.getItem(localStorageKeys.auth.token)
        return state.token || token || ''
      }

      return ''
    },
    getTokenForRequest (){
      return this.getToken.replace('Bearer%20', '')
    },
    getFavourites (state) {
      return state.favourites || []
    },
    getIsInitiated (state){
      return state.isInitiated
    }
    ,
    noFavourites (){
      return this.getFavourites.length === 0
    },
    populatedFavourites (){
      return this.getFavourites.filter(x => x?.id && x?.url)
    },
    unpopulatedFavourites (){
      return this.getFavourites.filter(x => x?.id && !x?.url)
    },
    getUser (state) {
      return state.user
    },
    getBookings: state => state?.bookings || [],
    isLoggedIn: (state) => {
      if (!state.user) {
        return false
      }

      return state.user?.exp && dayjs(state.user.exp * 1000).isAfter(dayjs())
    },
    username (state) {
      if (!this.isLoggedIn) {
        return null
      } else if (state.user.name) {
        return state.user.name
      } else if (state.user.nickname) {
        return state.user.nickname
      }
      return null
    },
    avatar (state) {
      const userImage = state.user?.picture
      if (!!userImage && userImage.toString().includes('http')) {
        return userImage
      } else if (state.user?.email) {
        return getGravatarByEmail(state.user.email)
      }
      return null
    },
    getMemberActiveSingle () {
      return this.isLoggedIn && this.memberActiveSingle
    },
    getMemberProfileConfig () {
      return this.isLoggedIn && this.memberProfile?.config
    },
    getMemberSingleList () {
      return this.isLoggedIn && this.memberSingleList
    },
  },
  actions: {
    SET_MEMBER_SINGLE_FINDER_LIST (data) {
      this.memberSingleList = data
    },
    SET_MEMBER_PROFILE (data) {
      this.memberProfile = data
    },
    SET_MEMBER_ACTIVE_SINGLE_FINDER (data) {
      this.memberActiveSingle = data
    },
    SET_MEMBER_PAGE_TAB (data) {
      this.memberPageTab = data
    },
    SET_MEMBER_PAGE_TABS (data) {
      this.memberPageTabs = data
    },
    SET_MEMBER_PAGE_PATH (data) {
      this.memberPagePath = data
    },
    //old mutations
    SET_TOKEN (token = null) {
      this.authFeedback = null
      if (process.browser) {
        this.token = token
      }
    },
    SET_USER (user) {
      this.user = { ...user }
    },
    SET_LOADING (loading) {
      this.loading = loading
    },
    SET_FEEDBACK (feedback) {
      this.feedback = feedback
    },
    SET_BOOKINGS (bookings) {
      this.bookings = bookings
    },
    POPULATE_BOOKING (booking) {
      const index = this.bookings.findIndex(item => item?.data?.ocr?.value === booking?.data?.ocr?.value)
      const list = this.bookings
      list[index] = booking
      this.bookings = [...list]
    },
    SET_FAVOURITES (favourites) {
      this.favourites = [...favourites]
    },
    ADD_FAVOURITE (favourite) {
      this.favourites = [...this.favourites, favourite]
    },
    CLEAR_AND_LOGOUT () {
      this.$reset()

      if (process.browser) {
        this.memberActiveSingle = false
        window.localStorage.removeItem(localStorageKeys.auth.token)
        window.localStorage.removeItem(localStorageKeys.auth.user)
      }
    },
    POPULATE_FAVOURITE (favourite) {
      const index = this.favourites.findIndex(({ id }) => id === favourite.id)
      const list = this.favourites
      list[index] = favourite
      this.favourites = [...list]
    },
    REMOVE_FAVOURITE (tripTypeId) {
      this.favourites = [...this.favourites.filter(({ id }) => id !== tripTypeId)]
    },
    SET_AUTH_FEEDBACK (authFeedback) {
      this.authFeedback = authFeedback
    },
    SET_INITIATED (isInitiated) {
      this.isInitiated = isInitiated
    },
    //old actions
    async init ({ token }) {
      if (token) {
        this.setToken(token)
      }

      if (!this.isInitiated) {
        await this.listBookings()
      }

      this.SET_INITIATED(true)
      return true
    },

    setUser (user) {
      this.SET_USER(user)

      if (process.browser) {
        this.backupState()
      }
    },

    setToken (token) {
      this.SET_TOKEN(token)

      if (process.browser) {
        this.backupState()
      }
    },

    async listBookings () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const washUrl = (url, full = false) => localeStore.washExternalLinkToInternal(url, full)
      let result = []
      this.SET_BOOKINGS([])
      this.SET_LOADING(true)
      this.SET_FEEDBACK(null)

      try {
        const { data: res } = await apiFetch(`/${locale}/customer/mybookings/list`, {
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: {
            token: this.getTokenForRequest,
          },
        })
        result = res
        this.SET_BOOKINGS(result.map(booking => ({
          ...booking,
          url: washUrl(booking?.url),
        })))
      } catch (e) {
        this.SET_LOADING(false)
        this.SET_FEEDBACK(e.message)

        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
      }

      this.SET_LOADING(false)
    },

    async listFavourites () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result = []

      this.SET_FAVOURITES([])
      this.SET_LOADING(true)
      this.SET_FEEDBACK(null)

      try {
        const { data: res } = await apiFetch(`/${locale}/customer/hearts-triptype/list`, {
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          addToken: true,
        })
        result = res
      } catch (e) {
        this.SET_LOADING(false)
        this.SET_FEEDBACK(e.message)

        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
      }

      const parsedResult = result.map(result => ({ ...result, id: result?.triptypeid }))

      this.SET_LOADING(false)
      this.SET_FAVOURITES(parsedResult)
    },

    async populateFavourites () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      this.SET_LOADING(true)
      this.SET_FEEDBACK(null)

      const unpopulatedFavourites = this.unpopulatedFavourites

      try {
        await Promise.allSettled(
          unpopulatedFavourites.map(({ id }) => apiFetch(`/${locale}/triptype?id=${id}`))
        )
          .then((x) => {
            (x || [])
            /* eslint-disable-next-line */
              .map(({ status, value, reason }, index) => {
                if (status === 'fulfilled') {
                  this.POPULATE_FAVOURITE(value.data)
                } else {
                  const { $sentryCaptureMessage } = useNuxtApp()
                  $sentryCaptureMessage('rolfs/admin/could not load favourite', {
                    level: 'warning',
                    contexts: { reason, ...unpopulatedFavourites[index] },
                    tags: { type: 'UX', source: 'rolfs/admin' },
                  })
                  return null
                }
              })
              .filter(Boolean)
          })

        this.SET_LOADING(false)
      } catch (e) {
        this.SET_LOADING(false)
        this.SET_FEEDBACK(e.message)

        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
      }
    },

    async addFavourite (tripTypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/hearts-triptype/add`, {
          method: 'post',
          addToken: true,
          body: {
            triptypeid: tripTypeId,
          },
        })
        result = res
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      if (result.status === 'success') {
        this.ADD_FAVOURITE({ id: tripTypeId })
        return true
      }

      return false
    },

    async removeFavourite (tripTypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      try {
        const { data: result } = await apiFetch(`/${locale}}/customer/hearts-triptype/delete`, {
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          addToken: true,
          body: {
            triptypeid: tripTypeId,
          },
        })
        if (result.status === 'success') {
          this.REMOVE_FAVOURITE(tripTypeId)
          return true
        } else {
          const { $sentryCaptureMessage } = useNuxtApp()
          $sentryCaptureMessage('rolfs/admin/could not add favourite', {
            level: 'warning',
            contexts: { tripTypeId, ...result },
            tags: { type: 'UX', source: 'rolfs/admin' },
          })
        }
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
      }
      return false
    },

    toggleFavourite (tripTypeId) {
      const method = this.favourites.map(({ id }) => id).includes(tripTypeId) ? 'remove' : 'add'
      return this[`${method}Favourite`](tripTypeId)
    },

    clearUserForLogout () {
      this.CLEAR_AND_LOGOUT()
      this.backupState()
    },

    backupState () {
      try {
        window.localStorage.setItem(localStorageKeys.auth.user, this.user)
        window.localStorage.setItem(localStorageKeys.auth.token, this.token)
      } catch {
        // ignore error
      }
    },

    async fetchMemberProfile () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/profile`, {
          method: 'post',
          addToken: true,
        })
        result = res
        this.SET_MEMBER_PROFILE(result)

        // Check the single_finder key and set memberActiveSingle accordingly
        if (result.config && result.config.single_finder !== undefined) {
          this.SET_MEMBER_ACTIVE_SINGLE_FINDER(result.config.single_finder === 1)
        } else {
          this.SET_MEMBER_ACTIVE_SINGLE_FINDER(false)
        }
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async updateMemberProfile (body) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/profile/update`, {
          method: 'post',
          addToken: true,
          body: {
            ...body,
          },
        })
        result = res
        this.SET_MEMBER_PROFILE(result)
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async fetchMemberSingleFinderList () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/single-finder/list`, {
          method: 'post',
          addToken: true,
        })
        result = res
        this.SET_MEMBER_SINGLE_FINDER_LIST(result)
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async memberSingleFinderAdd (tripid) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/single-finder/add`, {
          method: 'post',
          addToken: true,
          body: {
            trip_id: tripid,
          },
        })
        result = res
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async memberSingleFinderRenew (tripid) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/single-finder/renew`, {
          method: 'post',
          addToken: true,
          body: {
            trip_id: tripid,
          },
        })
        result = res
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async memberSingleFinderDelete (tripid) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/single-finder/remove`, {
          method: 'post',
          addToken: true,
          body: {
            trip_id: tripid,
          },
        })
        result = res
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },

    async memberSingleFinderContact (payload) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let result
      try {
        const { data: res } = await apiFetch(`/${locale}/customer/single-finder/contact`, {
          method: 'post',
          addToken: true,
          throwOnFail: false,
          body: {
            ...payload,
          },
        })
        result = res
      } catch (e) {
        const { $sentryCaptureException } = useNuxtApp()
        $sentryCaptureException(e)
        return
      }

      return result
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
