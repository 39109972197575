
export type Storage = {
  get: (key: string) => string | null | Promise<string | null>
  remove: (key: string) => void | Promise<void>
  set: (key: string, data: any) => void | Promise<void>
}

const storages: {
  [key: string]: Storage
} = {
  localStorage: {
    set: (key: string, data: string) => window.localStorage.setItem(key, data),
    get: (key: string) => window.localStorage.getItem(key),
    remove: (key: string) => window.localStorage.removeItem(key),
  }
}

export default storages