import { setCached } from '@packages/cache/utils'
import storages from '@packages/cache/storages'
import localStorageKeys from '@layers/web/constants/local-storage-keys.ts'
import expirations from '@packages/cache/expirations'

/**
 * Made to allow ads to enable singlefinder via a query param
 */
const ENABLE_SINGLE_FINDER_QUERY_KEY = 'singlefinder'

export default defineNuxtPlugin(() => {
  const route = useRoute()

  const enableSingleFinder = () => {
    setCached(
      localStorageKeys.singleFinderTempEnable,
      '1',
      expirations.halfHour,
      storages.localStorage
    )
  }

  if (route.query[ENABLE_SINGLE_FINDER_QUERY_KEY]) {
    enableSingleFinder()
    delete route.query[ENABLE_SINGLE_FINDER_QUERY_KEY]
  }
})