import equal from 'fast-deep-equal'

export const calendarFilterKeys = [
  'filters',
  'selectedStartDate',
  'filterTripType',
  'filterDuration',
]

const initialState = () => ({
  calendarInit: {
    departures: [],
    next: null,
    prev: null,
  },
  tripTypesMap: [],
  calendarOptions: null,
  filters: {
    triptypeid: '',
    daysmin: '',
    daysmax: '',
    staffid: '',
    onlyflight: 0,
    onlybus: 0,
    airport: 'all',
    fromdate: '',
    untildate: '',
    hide_soldout: 1,
    singleFinder: 0,

    activities: [],
    regions: [],
  },
  selectedStartDate: {
    currentDate: null,
    selectedDate: false,
    selectedDateTime: false,
    selectedHour: '00',
    selectedMinute: '00',
    selectedDatesItem: '',
    selectedDates: [],
    dateRange: {
      start: '',
      end: '',
    },
    multipleDateRange: [],
  },
  filterDuration: 0,
  filterTripType: 'all',
  startPageCampaign: null,
  campaignId: null,
  forceAirport: null,
  campaignVariant: null,
  filterLastMinute: false,
  calendarAbortController: null,
  mapAbortController: null,
  initialSearchQuery: null,
})

const initialFilterState = getObjectOnlyProperties(initialState(), calendarFilterKeys)

export const useCalendarStore = defineStore('calendar', {
  state: () => initialState(),
  getters: {
    isAnyFilterActive: state => !equal(getObjectOnlyProperties(state, calendarFilterKeys), initialFilterState),
  },
  actions: {
    //old mutations
    SPREAD_CALENDAR (data) {
      Object.entries(data).forEach(([key, value]) => {
        if (this[key] && typeof this[key] === 'object') {
          this[key] = { ...this[key], ...value }
        } else {
          this[key] = value
        }
      })
    },

    SET_CALENDAR_INIT (data) {
      this.calendarInit = data
    },

    SET_CALENDAR_OPTIONS (data) {
      this.calendarOptions = data
    },

    RESET_FILTERS () {
      const initalStateObject = initialState()
      this.filters = initalStateObject.filters
      this.selectedStartDate = initalStateObject.selectedStartDate
      this.filterDuration = 0
      this.filterTripType = 'all'
    },

    SET_FILTER ({ filter, value }) {
      this.filters[filter] = value
    },

    SET_SELECTED_STARTDATE (data) {
      this.selectedStartDate = data
    },

    SET_TRIPTYPE (data) {
      this.filterTripType = data
    },

    SET_DURATION (data) {
      this.filterDuration = data
    },

    SET_LASTMINUTE (data) {
      this.filterLastMinute = data
    },

    SET_TRIP_TYPES_MAP (data) {
      this.tripTypesMap = data
    },
    SET_FORCE_AIRPORT (iata) {
      this.forceAirport = iata
    },
    SET_CAMPAIGN_ID (campaign) {
      this.campaignId = campaign
    },
    SET_CAMPAIGN_VARIANT (variant) {
      this.campaignVariant = variant
    },
    SET_START_PAGE_CAMPAIGN (Boolean) {
      this.startPageCampaign = Boolean
    },
    //old actions
    getCalendarInit (payload) {
      return this.getCalendar(payload)
    },

    resetFilters (resetQuery = true) {
      this.RESET_FILTERS()

      if (resetQuery) {
        this.backupStateQuery(true)
      }
    },

    setFilter (data) {
      const hasChanged = !equal(this.filters[data.filter], data.value)

      this.SET_FILTER(data)

      if (hasChanged) {
        this.backupStateQuery()
      }
    },

    setSelectedStartDate (data) {
      this.SET_SELECTED_STARTDATE(data)
      this.backupStateQuery()
    },

    setTripType (data) {
      this.SET_TRIPTYPE(data)
      this.backupStateQuery()
    },

    setDuration (data) {
      this.SET_DURATION(data)
      this.backupStateQuery()
    },

    async getCalendar (payload) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (!payload) {
        payload = { ...this.filters }
        if (this.campaignVariant === 'airport') {
          payload.airport = this.forceAirport
          payload.onlydiscounted = 0
          if (this.campaignId) {
            payload.campaign = this.campaignId
          }
        } else if (this.filterLastMinute) {
          payload.onlydiscounted = 1
          if (this.campaignId) {
            payload.campaign = this.campaignId
            if (this.startPageCampaign) {
              payload.p = 32
            }
          }
        }
      }

      payload = deepClone(payload)

      if (payload.activities?.length) {
        payload.categories = payload.activities
        delete payload.activities
      }
      if (payload.regions?.length) {
        payload.categories = payload.categories || []
        payload.categories = [
          ...payload.categories,
          ...payload.regions,
        ]
        delete payload.regions
      }
      if (this.forceAirport) {
        payload.onlyflight = 1
        payload.onlybus = 0
        payload.airport = this.forceAirport
      }

      const dontStore = payload.dontStore
      delete payload.dontStore

      let params = ''
      Object.keys(payload).forEach((key) => {
        if (Array.isArray(payload[key])) {
          if (!payload[key].length) {
            return
          }

          params += `${key}=${payload[key].join(',')}&`
        } else if (payload[key]) {
          params += `${key}=${payload[key]}&`
        }
      })

      if (process.browser) {
        this.calendarAbortController?.abort()
        this.calendarAbortController = new AbortController()
      }

      const searchPath = `/${locale}/calendar?${params}`
      try {
        const { data: result } = await apiFetch(searchPath, {
          signal: this.calendarAbortController?.signal,
        })

        this.initialSearchQuery = searchPath

        if (!dontStore) {
          this.SET_CALENDAR_INIT(result)
        }

        return result
      } catch (e) {
        if (!e.data && !e.message) {
          return { aborted: true }
        }

        return { error: true }
      }
    },

    async getCalendarPage (url) {
      const { data } = await apiFetch(url)

      this.SET_CALENDAR_INIT(data)
    },

    async getAndAppendCalendarPage (url) {
      const { data } = await apiFetch(url)

      this.SET_CALENDAR_INIT({
        ...data,
        departures: [
          ...(this.calendarInit?.departures ?? []),
          ...(data?.departures ?? []),
        ],
      })
    },

    async getCalendarOptions () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (this.calendarOptions) {
        return
      }

      const { data: result } = await apiFetch(`/${locale}/calendar-filter-options-2`)

      this.SET_CALENDAR_OPTIONS(result)
      return result
    },

    async fetchTripTypesMap (payload) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      this.SET_TRIP_TYPES_MAP([])

      if (!payload) {
        payload = this.filters
      }

      payload = deepClone(payload)

      if (payload.activities?.length) {
        payload.categories = payload.activities
        delete payload.activities
      }
      if (payload.regions?.length) {
        payload.categories = payload.categories || []
        payload.categories = [
          ...payload.categories,
          ...payload.regions,
        ]
        delete payload.regions
      }

      const params = Object.entries(payload)
        .filter(([, value]) => !isEmpty(value))
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      if (process.browser) {
        if (this.mapAbortController) {
          this.mapAbortController.abort('New mapAbortController call initiated.')
        }
        this.mapAbortController = new AbortController()
      }

      try {
        const { data: result } = await apiFetch(`/${locale}/triptypes-map?${params}`, {
          signal: this.mapAbortController?.signal,
        })
        this.SET_TRIP_TYPES_MAP(result)
        this.mapAbortController = null
        return result
      } catch (e) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage(e, 'error')
      }
    },

    setFromBackupStateQuery (fullPath) {
      const decodedUrlQuery = decodeUrlQuery(fullPath || window?.location.search)
      const calendarState = getObjectOnlyProperties(decodedUrlQuery, calendarFilterKeys, true)

      if (calendarState) {
        this.SPREAD_CALENDAR(calendarState)
      }
    },

    /**
     * Only calendar filters cache, only browser
     * @param {Boolean} clear
     * @param {String[]} ignoreParams other params that might be in url to keep/ignore
     */
    backupStateQuery (clear) {
      if (!process.browser) {
        return
      }

      const backupStateObject = { ...this }
      if (this.forceAirport) {
        const initialStateObject = initialState()
        backupStateObject.filters.airport = initialStateObject.filters.airport
        backupStateObject.filters.onlybus = initialStateObject.filters.onlybus
        backupStateObject.filters.onlyflight = initialStateObject.filters.onlyflight
        backupStateObject.filterTripType = initialStateObject.filterTripType
      }

      const { pathname, search } = window.location
      const newQueryParams = updateQueryObject({
        targetObject: this,
        defaultObject: initialFilterState,
        queryObjectKeys: calendarFilterKeys,
        path: search.replace('?', ''),
        clear,
      })
      const newQueryParamsString = UrlEncoder.encode(newQueryParams)

      let newUrl = pathname
      if (newQueryParamsString) {
        newUrl += '?' + newQueryParamsString
      }

      window.history.replaceState({}, '', newUrl)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCalendarStore, import.meta.hot))
}
